<template>
    <ODataTable :dataObject="local_dsESignatures" hideGridMenu hideActionColumn ref="ref_ODataTable2" :headerTitle="$t('E-Signatures')">
        <template #cardheader>
            <OActionList>
                <template #target="{ target }">
                    <button :ref="target" class="btn btn-sm btn-outline-primary dropdown-toggle ms-2">
                        {{ $t("Tasks") }}
                        <div v-if="isExecutingTask" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">{{ $t("Loading...") }}</span>
                        </div>
                    </button>
                </template>
                
                <OActionItem :text="$t('Update Session')" @click="processUserRequest('UpdateStatusRoute')" :title="$t('Updated E-Signatures from solution provider through API request.')" />
                <!-- <OActionItem :text="$t('Update Recipients')" @click="updateRecipients" :title="$t('This is only used when recipient is added directly through the solution provider, not through Omega 365.')" /> -->
                <OActionItem :text="$t('Edit Sent Session')" @click="processUserRequest('SendUpdateRoute')" :title="$t('This will resend the session with updated information.')" />
                <OActionItem :text="$t('Resend Session')" @click="processUserRequest('ResendRoute')" :title="$t('This will resend the session to all participants.')" />
            </OActionList>
        </template>
        <OColumn field="ID" width="80" sortable hide/>
        <OColumn field="Process" width="150" :headerName="$t('Solution')" :hide="props.url == '/nt/scope-workflow'" sortable/>
        <OColumn field="EnvelopeId" width="300" :headerName="$t('Session ID')"   :hide="props.url == '/nt/scope-workflow'"  sortable/>
        <OColumn field="SentDate" width="130" :headerName="$t('Sent')"  format="General Date Short Time" :hide="props.url == '/nt/scope-workflow'" sortable/>
        <OColumn field="Status" width="110" sortable>
            <template #default="{row}"> 
                <i :class="{'bi bi-trash text-secondary': row.Status?.toLowerCase() === 'deleted',
                    'bi bi-check-circle text-success': row.Status?.toLowerCase() === 'completed',
                    'bi bi-envelope text-warning': row.Status?.toLowerCase() === 'sent'}">
                </i>
                {{ row.Status }}
            </template>
        </OColumn>
        <OColumn field="Completed" width="130" :headerName="$t('Completed')" :hide="props.url == '/nt/scope-workflow'" format="General Date Short Time" sortable/>
        <OColumn colId="Recipients" field="AR1.Recipients" :headerName="$t('Recipients')" width="350"  v-slot="{row}"
                filter="OFilter" :filterParams="{
                        autocomplete: {
                            existingValuesOnly: true,
                            awaitForAutocomplete: true,
                            disableAutoSelect: true
                        }
                    }" >
            <ul>
                <li v-for="(extSig, index) in row.AR1?.Recipients" :key="index" >
                        {{ extSig.Name }} ({{ extSig.SignedDate ? $formatDate(extSig.SignedDate , 'General Date Short Time') : "Pending" }})

                    <button v-if="!extSig.SignedDate && extSig.PendingMySignature && row.SignRoute && !row.Deleted" class="btn btn-outline-primary btn-sm float-end" 
                        :title="$t('Your signature is required. Please sign.')" @click="processUserRequest('SignRoute')">
                        <i class="bi bi-pencil"></i>
                    </button>
                </li>
            </ul>
        </OColumn>
        <OColumn colId="Files" field="AR2.Files" :headerName="$t('Files')" width="350" v-slot="{row}"
                filter="OFilter" :filterParams="{
                        autocomplete: {
                            existingValuesOnly: true,
                            awaitForAutocomplete: true,
                            disableAutoSelect: true
                        }
                    }" >
            <ul class="list-unstyled">
                <li v-for="(extSig, index) in row.AR2?.Files" :key="index" >
                    <a :href="`/nt/api/view-pdf/sviw_System_ExternalSignatureAttachments/${extSig.PrimKey}`" target="_blank" class="text-decoration-none">
                        {{ extSig.FileName }}
                    </a>
                </li>
            </ul>
        </OColumn>
        <OColumn field="Updated" width="130" sortable format="General Date Short Time"  :hide="props.url == '/nt/scope-workflow'" />
        <OColumn colId="customAction" :width="28" headerName="" suppressMovable>
            <template #default="{row}" >
                <OActionDelete :dataObject="local_dsESignatures" v-if="!row.Deleted && local_dsESignatures.current.ID == row.ID && row.DeleteRoute" title="Delete record" 
                    style="cursor: pointer;left: 1px;position: relative;" :row="row" class="btn btn-sm btn-link p-0 text-center">
                    <i class="bi bi-x-lg" ></i>
                </OActionDelete>
            </template>
        </OColumn>
    </ODataTable>
</template>
<script setup lang="ts">
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { OActionDelete } from "o365-data-components";
    import { ref } from 'vue';
    import { ODataTable } from 'o365-datagrid'
    import { useDataObjectEventListener } from 'o365-vue-utils';
    import { alert } from 'o365-vue-services';
    import { confirm as confirmDialog } from 'o365-vue-services'
    import { ODropdown, OActionList, OActionGroup, OActionItem } from 'o365-ui-components';

    const isExecutingTask = ref(false);

    const props = defineProps({
        dataObject: Object,
        source: {type: String},
        source_Id: {type: Number},
        url: {type: String}
    })

    const local_dsESignatures =  getOrCreateDataObject({
        id: 'local_dsESignatures',
        viewName: 'sviw_System_ExternalSignatures',
        appId: props.dataObject.appId,
        maxRecords: 50,
        whereClause: "EnvelopeId IS NOT NULL",
        loadRecents: false,
        distinctRows: false,
        disableAutoLoad: false,
        allowDelete: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: props.source,
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number" },
                {name: "EnvelopeId"},
                {name: "Name"},
                {name: "Status"},
                {name: "Created", type: "datetime"},
                {name: "SentDate", type: "datetime"},
                {name: "Completed", type: "datetime"},
                {name: "EnvelopeId"},
                {name: "Process"},
                {name: "Process_ID", type: "number" },
                {name: "Deleted", type: "datetime"},
                {name: "OrgUnit_ID", type: "number" },
                {name: "SharedFolder_ID", type: "number" },
                { name: "CreatedBy_ID", type: "number" },
                { name: "Updated", type: "datetime" },
                { name: "UpdatedBy_ID", type: "number" },
                { name: "ScopeItem_ID", type: "number" },
                { name: "ProcurementStage_ID", type: "number" },
                { name: "Document_ID", type: "number" },
                { name: "DeleteRoute" },
                { name: "UpdateStatusRoute" },
                { name: "SendUpdateRoute" },
                { name: "ResendRoute" },
                { name: "SignRoute" },
                
            ],
        subSelectDefinitions: [
            {
            "fields": [
                {
                    "name": "Recipients",
                    "subFields": [
                       "Name",
                        "SignedDate",
                        "PendingMySignature",
                        "ExternalSignature_ID"
                    ]
                }
            ],
            "bindings": [
                {
                    "masterField": "ID",
                    "operator": "equals",
                    "detailField": "ExternalSignature_ID"
                }
            ],
            "alias": "AR1",
            "viewName": "sviw_System_ExternalSignaturesRecipients"
        },
        {
            "fields": [
                {
                    "name": "Files",
                    "subFields": [
                        "PrimKey",
                        "FileName",
                        "FileSize",
                        "FileUpdated"
                    ]
                }
            ],
            "bindings": [
                {
                    "masterField": "ID",
                    "operator": "equals",
                    "detailField": "ExternalSignature_ID"
                }
            ],
            "alias": "AR2",
            "viewName": "sviw_System_ExternalSignatureAttachments"
        }]
    })
    
    useDataObjectEventListener(local_dsESignatures,'BeforeDelete', (pOptions, pRow) => { 
        pOptions.cancelEvent = true;
        confirmDialog({
            message: $t(`Deleting this will also mark the envelope as deleted in ${pRow.Process}. Do you wish to proceed?`),
            title: $t(`Delete E-Signature session`),
        }).then(async () => {
            processUserRequest('DeleteRoute');
        }).catch(() => {});
        
    });

    const processUserRequest = async(pAction) => {
        var vInfo = alert( "Updating...", 'Info', { autohide : false } );
        isExecutingTask.value = true;
        
        var vReturnUrl = window.location.protocol + "//" + window.location.hostname  + props.url + '?ID=' + props.source_Id;

        if(local_dsESignatures.current[pAction] === null) {
            alert("Action cannot be performed due to missing route configuration.", 'warning', { autohide: true, delay: 3000 });
            isExecutingTask.value = false;
            vInfo?.close();
            return;
        }

        if ((local_dsESignatures.current.EnvelopeId === null || local_dsESignatures.current.Deleted !== null || local_dsESignatures.current.Completed !== null) && pAction !== 'Delete') {
            alert("Please select only one session that is not deleted / completed and try again.", 'warning', { autohide: true, delay: 3000 });
            isExecutingTask.value = false;
            vInfo?.close();
            return;
        }

        if(pAction === 'SendUpdate') {
            const urlsources = {
                    Document_ID: 'document',
                    ProcurementStage_ID: 'procurement',
                    SharedFolder_ID: 'sharedFolder',
                    ScopeItem_ID: 'scope'
            };
            
            window.open(`/nt/externalsignature-new?ID=${local_dsESignatures.current.ID}&${props.source}=${props.source_Id}&source=${urlsources[props.source]}&sessionId=${local_dsESignatures.current.EnvelopeId}`, "_parent"); 
        } else {
            fetch(local_dsESignatures.current[pAction],
            {method: 'POST', 
                body:JSON.stringify({ ExternalSignature_ID: local_dsESignatures.current.ID, EnvelopeId: local_dsESignatures.current.EnvelopeId, ReturnUrl:vReturnUrl }), 
                headers: {"Content-Type": "application/json"}}
            ).then(async (response) => {
                vInfo?.close();
                isExecutingTask.value = false;
                if(response.status != 200){
                    response.text().then(errorBody => {
                        console.log(errorBody)
                        alert(errorBody.split(' at ')[0] ?? errorBody,'danger');
                    });
                } else { 

                    let url =  await response.json();
                    
                    if(url) {
                        window.open(url, "_parent"); 
                    } else {
                        props.dataObject.load();
                    }
                }
            });
        }
    }
    local_dsESignatures.load();
</script>